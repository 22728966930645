*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 100px 10px 100px;
    position: relative;
    z-index: 2;
    border-bottom: 1px solid brown;
}
.nav-logo{
    width: 100px;
    height: 60px;
}
.nav-logo img{
    width: 100%;
    height: 100%;
}
.navbar{
    position: relative;
}
.nav-menu{
    display: none;
    cursor: pointer;
    color: brown;
}
.nav-menu:hover{
    color: blueviolet;
}
.nav-links{
    display: flex;
}
.nav-links li{
    list-style-type: none;
    margin-left: 20px;
}
.nav-links li a{
    text-decoration: none;
    color: brown;
    font-size: 1.4rem;
    text-transform: capitalize;
    font-family: sans-serif;
}
.nav-links li a:hover{
    color: blueviolet;
}


  @media (max-width: 960px){
    nav{
        padding: 10px 50px 10px 50px;
    }
  }
  
  @media (max-width: 720px){
    .nav-menu{
      display: block;
    }
    .nav-links{
        display: flex;
        flex-direction: column;
        padding-top: 20px;

        background-color: rgba(165, 42, 42, 0.6);
        position: absolute;
        right: -50px;
        top: 53px;
        width: 250px;
        height: 100vh;
        justify-content: right;
        align-items: start;
        
    }
    .nav-links li{
        text-align: right;
        margin-bottom: 20px;
    }
    .nav-links li a{
        color: white;
        font-size: 1.2rem;
        text-align: right;
    }
    .open{
        display: none;
    }

  }

  @media (max-width: 420px){
    nav{
        padding: 10px 20px 10px 20px;
    }
  }