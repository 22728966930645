.policy-main{
    background: linear-gradient(to bottom, rgb(100, 17, 17), rgb(236, 138, 138) );
    padding: 100px;
    width: 100%;
    height: 100%;
}
.policy-detail{
    background: white;
    border-radius: 20px;
    padding: 50px;
}
.policy-detail h2{
    color: grey;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
}
.policy-img{
    padding: 10px;
    border-radius: 10px;
    height: 300px;
    width: 60%;
    margin: 0 auto;
}
.policy-img img{ 
    width: 100%;
    height: 100%;
}
.policy-words{
    padding: 40px 20px;
    border-radius: 0 0 20px 20px;
    background: brown;
    color: white;
}
.policy-words p{
    margin-bottom: 10px;
}
.policy-words a{
    display: block;
    color: white;
    margin-bottom: 10px;
}



@media (max-width: 960px){
    .policy-img{
        width: 80%;
        margin: 0 auto;
    }
  }
  
  @media (max-width: 720px){
    .policy-detail h2{
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .policy-main{
        padding: 70px 50px;
    }
    .policy-detail{
        padding: 30px;
    }
    .policy-img{
        width: 100%;
        margin: 0 auto;
        padding: 10px 0;
    }
    .policy-words{
        padding: 10px 20px;
    }
  }

  @media (max-width: 480px){
    .policy-detail h2{
        font-size: 1.5rem;
    }
    .policy-img{
        height: 200px;
    }
  }

  @media (max-width: 420px){
    .policy-detail h2{
        font-size: 1.3rem;
        margin-bottom: 10px;
    }
    .policy-main{
        padding: 40px 20px;
    }
    .policy-detail{
        padding: 10px 0 0 0;
    }
    .policy-img{
        height: 150px;
        padding: 0;
    }
    .policy-words{
        padding: 20px;
    }
  }