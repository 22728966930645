footer{
    background-color: rgb(100, 17, 17);
    color: white;
    padding: 20px 100px 20px 100px;
    position: relative;
}
.footer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}
footer ul li{
    list-style-type: none;
    text-align: center;
}
footer ul li a{
    text-decoration: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    
}
footer ul li a:hover{
    color: blueviolet;
}
.footer-info{
    margin-top: 5px;
}


@media (max-width: 960px){
    footer{
        padding: 20px 50px 20px 50px;
    }
  }

  @media (max-width: 720px){
    .footer{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 10px;
    }
  }


  @media (max-width: 420px){
 
  }